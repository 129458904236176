
#about {
  color:white;

  body {
    background-color: rgb(56, 56, 56);
    font: 13px 'Open Sans', verdana;
    color: #CECECE;
    padding: 50px;
    margin: 0px;
  }
  
  a:link, a:visited, a:hover {
   color: rgba(131, 216, 255, 0.719);
    transition: .3s;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover { color: rgba(131, 216, 255, 0.973); }
  
  #wrapper {
    background: rgba(2, 2, 2, 0.151);
    border-top: 4px solid rgba(2, 2, 2, 0.151);
    border-bottom: 4px solid rgba(2, 2, 2, 0.151);
    width: 700px;
    margin: auto;
  }
  
  #inner {
    padding: 30px;
    text-shadow: 1px 1px #242424;
  }
  
  .header {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #F1F1F1;
  }
  
  .secondary {
    padding-bottom: 3px;
    margin-bottom: 3px;
    color: rgba(145, 231, 137, 0.795);
  }
  
  .text {
    padding: 1rem;
    padding-top: 10px;
    line-height: 170%;
    text-align: justify;
    margin-bottom: 25px;
  }
  
  .text:last-child {
    margin-bottom: 0px;
  }
  
  .text span {
    color: rgb(255, 182, 122);
  }
  
  #contact-tbl, #color-tbl {
    display: table;
    margin: 0px;
    padding: 0px;
    margin-left: 30px;
  }
  
  #contact-tbl div, #color-tbl div {
    display: table-cell;
    width: 80px;
    text-align: left;
    vertical-align: middle;
  }
  
  #color-tbl div {
    width: 40px;
  }
  
  #color-tbl div:last-child {
    width: 200px;
  }
  
  #border {
    border: 1px solid rgba(255, 255, 255, 0.658);
  }

  .accordion-title {
    padding: 1rem;
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  .accordion-content {
    opacity: 100;
    height: auto;
    max-height: 9999px;
    transition: all 0.4s cubic-bezier(1,0,1,0);
  }

  .accordion-content-hidden {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0,1,0,1)
  }

  .rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.2s ease-in;
  }

  .rotate-0 {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: all 0.2s ease-in;
  }
}
