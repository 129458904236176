#innerbody {
   display:flex;
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;

   > .inner {
      display: flex;
      height: 100%;
      width: 100%;
      // padding: 6px;

      #map-wrapper, #panel-wrapper {
         display: table-cell;
         position: relative;
         border: 1px solid rgb(44, 44, 44);
      }

      #map-wrapper {
         background: rgba(0, 0, 0, 0.274);
         border-right: 0px;
         flex: 1 1 auto;
         overflow: hidden;
      }

      #panel-wrapper {
         display: flex;
         background: rgba(0, 0, 0, 0.185);
         width: 20%;
         min-width: 300px;
      }
   }
}

#innerbody-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

     #map-wrapper, #panel-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      border: 1px solid rgb(44, 44, 44);
      overflow-x: hidden;
      overflow-y: hidden;
     }
}