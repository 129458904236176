#map {  
   width: 100%;
   max-width: 1440px;
   height: 960px; // Can't set height as percentage. We need to keep aspect ratio constant.
   position: absolute;
   transition-property: background, top, left, opacity;
   transition-duration: 0.2s, 0.2s, 0.2s, 0.2s;
   opacity: 0;
   background-size: contain !important; // Responsive map with fixed aspect ratio.
   background-repeat: no-repeat !important;

 

   #primary-line, #secondary-line {
      stroke-width: 4px;
      opacity: 0.9;
      stroke: rgb(255, 39, 39);
   }

   #secondary-line {
      stroke: rgb(28, 68, 117);
   }

   #primary-line-border, #secondary-line-border {
      stroke-width: 6px;
      stroke: rgb(78, 9, 9);
   }

   #secondary-line-border {
      stroke: rgb(2, 14, 32);
   }

   foreignobject {
      z-index: 10;   
   }

   circle {
      opacity: 0;
      transition: opacity 0.2s;
      stroke: rgb(12, 12, 12);
      stroke-width: 1;
   }

   .vq-waypoint {
      position: absolute;
      width: 10px;
      height: 10px;

      span {
         background: rgba(0, 0, 0, 0.76);
         position: absolute;
         pointer-events: none;
         padding: 1px;
         border-radius: 25%;
         opacity: 1;

         #wp1 { background: url('../images/numbers/1.png'); }
         #wp2 { background: url('../images/numbers/2.png'); }
         #wp3 { background: url('../images/numbers/3.png'); }
         #wp4 { background: url('../images/numbers/4.png'); }
         #wp5 { background: url('../images/numbers/5.png'); }
         #wp6 { background: url('../images/numbers/6.png'); }
         #wp7 { background: url('../images/numbers/7.png'); }
         #wp8 { background: url('../images/numbers/8.png'); }
         #wp9 { background: url('../images/numbers/9.png'); }
      }

      #mapAnchortop { 
        position: absolute; 
        left: -5px; 
        top: -21px;
        width: 13px;
        height: 15px;
        img {
          margin-top: -8px;
        }
      }

      #mapAnchorbottom { 
        position: absolute; 
        left: -5px; 
        top: 10px;
        width: 13px;
        height: 13px;
        img {
          margin-top: -8px;
        }
      }

      #mapAnchorleft { 
        position: absolute; 
        left: -20px; 
        top: -6px;
        width: 13px;
        height: 13px;
        img {
          margin-top: -8px;
        }
      }

      #mapAnchorright { 
        position: absolute; 
        left: 10px; 
        top: -6px; 
        width: 13px;
        height: 13px;
        img {
          margin-top: -8px;
        }
      } 

      #quest,
      #travel,
      #objective,
      #flightpath,
      #hub {
         position: absolute;
         left: -5px;
         top: -5px;
      }
   
      #quest  { background: url('../images/waypoints/yellow.png'); }
      #travel { background: url('../images/waypoints/purple.png'); }
      #objective { background: url('../images/waypoints/red.png'); }
      #flightpath { background: url('../images/waypoints/green.png'); }
      #hub { background: url('../images/waypoints/blue.png'); }
   }
}

.map-mobile {
  position: relative !important;
  width: 100%;
  left: 0;
  top: 0;
}

#next, #prev {
   background: rgba(0, 0, 0, 0.24);
   position: absolute;
   height: 100% !important;
   transition: opacity 0.2s;
   cursor: pointer;
   width: 10%;
   background-repeat: no-repeat;
   background-position: center center;
   top: 0px;
   opacity: 0;
}

#prev {
   background-image: url('../images/prev.png');
   left: 0px;
}

#next {
   background-image: url('../images/next.png');
   right: 0px;
}

// VANILLA MAPS
#map.durotar { background: url('../images/maps/durotar.jpg'); }
#map.mulgore { background: url('../images/maps/mulgore.jpg'); }
#map.ungoro { background: url('../images/maps/ungoro.jpg'); }
#map.morogh { background: url('../images/maps/morogh.jpg'); }
#map.duskwood { background: url('../images/maps/duskwood.jpg'); }
#map.tanaris { background: url('../images/maps/tanaris.jpg'); }
#map.epl { background: url('../images/maps/epl.jpg'); }
#map.deadwind { background: url('../images/maps/deadwind.jpg'); }
#map.undercity { background: url('../images/maps/undercity.jpg'); }
#map.thunderbluff { background: url('../images/maps/thunderbluff.jpg'); }
#map.steppes { background: url('../images/maps/steppes.jpg'); }
#map.ashenvale { background: url('../images/maps/ashenvale.jpg'); }
#map.loch { background: url('../images/maps/loch.jpg'); }
#map.azshara { background: url('../images/maps/azshara.jpg'); }
#map.arathi { background: url('../images/maps/arathi.jpg'); }
#map.redridge { background: url('../images/maps/redridge.jpg'); }
#map.hillsbrad { background: url('../images/maps/hillsbrad.jpg'); }
#map.searing { background: url('../images/maps/searing.jpg'); }
#map.feralas { background: url('../images/maps/feralas.jpg'); }
#map.wpl { background: url('../images/maps/wpl.jpg'); }
#map.ironforge { background: url('../images/maps/ironforge.jpg'); }
#map.needles { background: url('../images/maps/needles.jpg'); }
#map.badlands { background: url('../images/maps/badlands.jpg'); }
#map.stonetalon { background: url('../images/maps/stonetalon.jpg'); }
#map.blasted { background: url('../images/maps/blasted.jpg'); }
#map.hinterlands { background: url('../images/maps/hinterlands.jpg'); }
#map.silverpine { background: url('../images/maps/silverpine.jpg'); }
#map.moonglade { background: url('../images/maps/moonglade.jpg'); }
#map.tirisfal { background: url('../images/maps/tirisfal.jpg'); }
#map.darnassus { background: url('../images/maps/darnassus.jpg'); }
#map.winterspring { background: url('../images/maps/winterspring.jpg'); }
#map.westfall { background: url('../images/maps/westfall.jpg'); }
#map.wetlands { background: url('../images/maps/wetlands.jpg'); }
#map.silithus { background: url('../images/maps/silithus.jpg'); }
#map.stormwind { background: url('../images/maps/stormwind.jpg'); }
#map.teldrassil { background: url('../images/maps/teldrassil.jpg'); }
#map.stv { background: url('../images/maps/stv.jpg'); }
#map.elwynn { background: url('../images/maps/elwynn.jpg'); }
#map.orgrimmar { background: url('../images/maps/orgrimmar.jpg'); }
#map.barrens { background: url('../images/maps/barrens.jpg'); }
#map.alterac { background: url('../images/maps/alterac.jpg'); }
#map.desolace { background: url('../images/maps/desolace.jpg'); }
#map.dustwallow { background: url('../images/maps/dustwallow.jpg'); }
#map.swamp { background: url('../images/maps/swamp.jpg'); }
#map.darkshore { background: url('../images/maps/darkshore.jpg'); }
#map.felwood { background: url('../images/maps/felwood.jpg'); }

// TBC MAPS -- TODO
// #map.hellfire { background: url('../images/maps/hellfire.jpg'); }
// #map.bem { background: url('../images/maps/bem.jpg'); }
// #map.nagrand { background: url('../images/maps/nagrand.jpg'); }
// #map.netherstorm { background: url('../images/maps/netherstorm.jpg'); }
// #map.shadowmoon { background: url('../images/maps/shadowmoon.jpg'); }
// #map.shattrath { background: url('../images/maps/shattrath.jpg'); }
// #map.terokkar { background: url('../images/maps/terokkar.jpg'); }
// #map.zangarmarsh { background: url('../images/maps/zangarmarsh.jpg'); }

// WOTLK MAPS

#map.boreantundra { background: url('../images/maps/boreantundra.jpg'); }
#map.crystalsongforest { background: url('../images/maps/crystalsongforest.jpg'); }
#map.dalaran { background: url('../images/maps/dalaran.jpg'); }
#map.dragonblight { background: url('../images/maps/dragonblight.jpg'); }
#map.grizzlyhills { background: url('../images/maps/grizzlyhills.jpg'); }
#map.howlingfjord { background: url('../images/maps/howlingfjord.jpg'); }
#map.hrothgarslanding { background: url('../images/maps/hrothgarslanding.jpg'); }
#map.icecrown { background: url('../images/maps/icecrown.jpg'); }
#map.sholazarbasin { background: url('../images/maps/sholazarbasin.jpg'); }
#map.thestormpeaks { background: url('../images/maps/thestormpeaks.jpg'); }
#map.wintergrasp { background: url('../images/maps/wintergrasp.jpg'); }
#map.zuldrak { background: url('../images/maps/zuldrak.jpg'); }
