@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/main.woff');
}

* {
    margin: 0px;
    padding: 0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#vq-root {
  background-color: rgb(0,0,0,0.4);
  // background-image: url('../images/bg.png');
  font: 13px 'Open Sans', verdana;
  color: #505050;

  a:link, a:visited, a:hover {
    color: inherit;
    transition: color 0.3s;
    text-decoration: none;
}

input {
    font: 13px 'Open Sans', verdana;
    outline: none;
}

.split {
    display: table;
    width: 100%;

    div {
        display: table-cell;
        width: auto;
        text-align: left;
        vertical-align: top;
    }

    div:nth-child(2) {
        text-align: right;
    }
  }
}

#vq-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: filter 0.2s;
    position: relative;
    padding-top: 50%;
}

#vq-wrapper-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: filter 0.2s;
    position: relative;
}

#vq-wrapper.active {
    filter: none;
}

#vq-wrapper.inactive {
    filter: blur(5px) grayscale(70%);
}

#error {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    #inner {
        background: rgba(0, 0, 0, 0.219);
        padding: 8px 12px;
        color: white;
        font-size: 18px;
        text-shadow: 1px 1px #242424;
    }
}