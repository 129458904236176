#panel {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   height: 100%;
   width: 100%;
   top: 0;

   #status, #logs {
      display: table-row;
   }

   #status {
      // height: 1px;

      #level, #progress, #hearthstone {
         background-image: url('../images/bg.png');
         padding: 5px 7px;
         color: rgb(214, 214, 214);
         margin-bottom: 1px;
         position: relative;
         transition: width 0.3s;
         text-shadow: 1px 1px #242424;
         text-transform: capitalize;
      }

      #level {
         background: rgba(44, 133, 44, 0.212);
      }
      
      #progress {
         background: rgba(194, 66, 194, 0.212);
         cursor: pointer;
      }

      #level .split, #progress .split {
         pointer-events: none;
      }

      #level #bar, #progress #bar {
         background: rgba(255, 255, 255, 0.062);
         border-right: 1px solid rgba(255, 255, 255, 0.11);
         transition: width 0.2s;
         position: absolute;
         top: 0px;
         left: 0px;
         width: 50%;
         height: 100%;
         pointer-events: none;
      }
      
      #hearthstone {
         background: rgba(231, 141, 39, 0.274);
         text-transform: capitalize;
      }

      #panel-menu {
         background: rgba(78, 255, 246, 0.247);
         background-image: url('../images/bg.png');
         border-bottom: 1px solid rgb(44, 44, 44);
         text-transform: capitalize;

         #objectives, #quests, #link {
            width: 40%;
            text-align: center;
            padding: 5px 7px;
            padding-bottom: 6px;
            color: white;
            cursor: pointer;
            transition: background 0.2s;
         }
         
         #objectives {
            border-right: 1px solid rgb(46, 46, 46);
         }

         #link {
            width: 20%;
            background: rgba(60, 114, 185, 0.5);
         }

         #link:hover {
            background: rgba(93, 144, 211, 0.5);
            text-decoration: underline;
         }

         .current {
            background: rgba(78, 255, 246, 0.089);
            pointer-events: none;
            text-decoration: underline;
         }
      }
   }

   #logs {
      text-transform: capitalize;
      overflow-y: scroll;
    
      -webkit-scrollbar { width: 7px; }
      -webkit-scrollbar-track { background: #00000038; }
      -webkit-scrollbar-thumb { background: rgba(255, 255, 255, 0.301); }

      scrollbar-color: rgba(255, 255, 255, 0.301) #00000038;
      scrollbar-width: thin;

      #objectives, #quests {
         padding: 4px;

         .section {
            background: rgba(0, 0, 0, 0.080);
            padding: 1px;
            margin-bottom: 4px;
            text-shadow: 1px 1px #242424;
            position: relative;
         }

         .section:last-child {
            margin-bottom: 2px;
         }

         .ends-icon, .completed-icon, .starts-icon, .objectives-icon, .special-icon {
            background-color: rgba(0, 0, 0, 0.199);
            background-image: url('../images/icons/ends.png');
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            height: 28px;
            width: 28px;
            margin-top: 1px;
            border-right: 1px solid rgba(0, 0, 0, 0.425);
         }

         .starts-icon { background-image: url('../images/icons/starts.png'); }
         .objectives-icon { background-image: url('../images/icons/objectives.png'); }
         .special-icon { background-image: url('../images/icons/special.png'); }

         .title {
            display: table;
            width: 100%;
            margin-top: 0px;
            background: rgba(255, 255, 255, 0.130);
            margin-top: 1px;
            padding: 5px 7px;
            color: #F1F1F1;

            div { display: table-cell; }
            div:last-child { text-align: right; }
         }

         .title, .ends-row, .completed-row, .starts-row, .objectives-row, .special-row, .quest {
            background: rgba(255, 255, 255, 0.130);
            margin-top: 1px;
            padding: 5px 7px;
            padding-left: 35px;
            color: #F1F1F1;
         }

         .title, .quest {
            padding-left: 7px;
         }

         .title {
            margin-top: 0px;
         }
         
         .ends-row { background: rgba(94, 207, 94, 0.342); }
         .completed-row { background: rgba(179, 85, 216, 0.507); }
         .starts-row { background: rgba(255, 255, 29, 0.37); }
         .objectives-row { background: rgba(226, 84, 84, 0.418); }
         .special-row { background: rgba(94, 125, 192, 0.507); }
         .quest { background: rgba(226, 171, 108, 0.486); }

         .special a {
            text-decoration: underline;
         }

         .section a:link, .section a:visited { color: rgb(250, 250, 250); }
         .section a:hover { text-decoration: underline; }
      }
   }


   ::-webkit-scrollbar { width: 7px; }
   ::-webkit-scrollbar-track { background: #00000038; }
   ::-webkit-scrollbar-thumb { background: rgba(255, 255, 255, 0.301); }
   
}