#prompt {
  background: rgba(0, 0, 0, 0.219);
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 99;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  #inner {
      transition: transform 0.2s;
      width: 60vh;

      #header {
          text-align: center;
          color: white;
          font-size: 19px;
          text-transform: uppercase;
          margin-bottom: 10px;
          text-shadow: 1px 1px #242424;
      }

      #create, #import, #export {
          background: rgba(0, 0, 0, 0.473);
          min-width: 300px;
          padding: 2px;
      }

      // CREATE PROMPT
      #create {
          width: 300px;
          margin: auto;
          display: flex;

          #content {
              background: rgba(255, 255, 255, 0.904);
              border: 0px solid black;
              border-bottom: 2px solid white;
              width: 200px;
              padding: 6px 9px;
              color: #333333;
              flex: 1;
          }

          #good, #bad {
              background: rgba(60, 199, 60, 0.466);
              border: 0px solid black;
              border-bottom: 2px solid rgba(60, 199, 60, 0.336);
              width: auto;
              margin-left: 2px;
              color: white;
              transition: 0.2s;
              padding: 6px 9px;
          }

          #good {
              cursor: pointer;
          }

          #bad {
              background: rgba(194, 65, 65, 0.822);
              border-bottom: 2px solid rgba(199, 70, 70, 0.897);
          }
      }


      // LOADING SCREEN -- https://loading.io/css/
      .lds-dual-ring {
          display: block;
          width: 80px;
          height: 80px;
          margin: auto;
      }

      .lds-dual-ring:after {
          content: " ";
          display: block;
          width: 80px;
          height: 80px;
          margin: 1px;
          border-radius: 50%;
          border: 6px solid #fff;
          border-color: #fff transparent #fff transparent;
          animation: lds-dual-ring 1.2s linear infinite;
      }

      @keyframes lds-dual-ring {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }
  }

  #inner.active {
      transform: translate(0px, 0px)
  }

  #inner.inactive {
      transform: translate(0px, 50px)
  }

  // CLOSE PROMPT CROSS
  #close {
      background: url('../images/close.png') center center no-repeat;
      position: absolute;
      bottom: 0px;
      right: 0px;
      height: 80px;
      width: 80px;
      opacity: 0.6;
      transition: 0.3s;
  }

  #close:hover {
      cursor: pointer;
      opacity: 1;
  }
}

#prompt::after {
  content: "";
  background: url('../images/bg.png');
  opacity: 0.6;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}

#prompt.active {
  opacity: 1;
  visibility: visible;
}

#prompt.inactive {
  opacity: 0;
  visibility: hidden;
}